@import "../theme"

$ring-pos: -2px

div.directory-selectable-avatar
  position: relative

  div.selected-ring
    position: absolute
    top: $ring-pos
    bottom: $ring-pos
    left: $ring-pos
    right: $ring-pos
    border: 5px solid $success
    border-radius: 50%

  div.vid-edit-controls
    position: absolute
    bottom: -1.6em
    left: 1em

    > button
      border: none
      background: none
      text-shadow: 0 0 0.7em black

      &.confirm
        color: $success
      
      &.cancel
        color: $danger