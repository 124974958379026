@import "../theme"

div.directory-nav
  position: relative

  div.banner

    > img.banner-img
      min-width: 100vw
      width: 100vw
      object-fit: cover
      height: 90px

    > a.back
      text-decoration: none
      position: absolute
      border: none
      background: none
      color: $color1
      top: 10px
      right: 10px