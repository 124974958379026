@import "./theme"
$orderedList: orderedList
$size: 1.2rem


html, body, div#root > div.container, .vh-100
  min-height: 100vh

div.page
  min-width: 100vh
  min-height: 100vh 


body
  background: linear-gradient($background1 88%, $background1)

ol.ordered-list
  counter-reset: $orderedList
  list-style: none

  > li
    margin-left: 1rem
    counter-increment: $orderedList
    position: relative

  &::before
    content: counter($orderedList)
    font-weight: bold
    font-size: 82%
    background: $primary
    width: $size
    height: $size
    text-align: center
    position: absolute
    margin-left: -($size + 0.4rem)
    border-radius: 50%
    top: 8%
  