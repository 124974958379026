@import "../theme"

div.directory-edit
  position: relative
  min-width: 100vw
  font-size: small

  button.close-btn
    position: absolute
    top: -2em
    right: 1em
    color: $danger
    background-color: $background4
    border: 1px solid $border1
    z-index: 10

  input:not([type=range]), textarea
    background-color: $background2
    border-color: $border1
    color: white
    font-size: 0.8em
