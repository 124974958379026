div.not-found-page

  height: 100vh

  h1, h3
    text-align: center
  
  h1
    font-size: 3.6rem

  img
    position: fixed
    right: 0
    bottom: 0