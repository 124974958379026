@import "../theme"

div.video-info
  font-size: 0.65em

  svg.icon
    font-size: 1.5em

  span.info-line

    color: #919191
    
    &:hover
      color: #dddddd

      > svg.airtime
        color: #3269d0

      > svg.member
        color: #eed61d

      > svg.download
        color: #24c915