@import "../theme"

$border-pos: 5px

div.video-card-editor-multi-select
  position: relative

  div.video-section
    position: relative

    div.selected-border
      position: absolute
      top: $border-pos
      bottom: $border-pos
      left: $border-pos
      right: $border-pos
      border: 5px solid $success
      border-radius: 4px