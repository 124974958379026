@import "../theme"

nav.nav
  background-color: $background5

  input:not([type=range]), textarea
    background-color: $background4
    border-color: $border1
    color: white

  span.input-group-text
    background-color: $background6
    border-color: $border1
    color: $color1